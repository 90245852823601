<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Información de la consulta</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="text-capitalize">
            {{ currentQuerySelected.query.comments }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  data() {
    return {
      panel: [0, 1],
    };
  },
};
</script>

<style lang="scss" scoped></style>
