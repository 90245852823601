<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Información del cliente</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Nombre</span>
          <span class="text-capitalize"
            >{{ currentQuerySelected.account.name }}
            {{ currentQuerySelected.account.surname }}</span
          >
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Rut</span
          >{{ currentQuerySelected.account.identificationNumber | formatRut }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Persona</span>
          {{
            currentQuerySelected.account.userType ==
            allConstants.security.userTypes.person
              ? "Natural"
              : "Juridica"
          }}
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Dirección</span>
          <span class="text-lowercase">{{ claimantFullAddress }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Email</span>
          <span class="text-lowercase">{{
            currentQuerySelected.account.email
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Teléfono</span>
          +56 9 {{ currentQuerySelected.account.cellphone }}
          {{
            currentQuerySelected.account.phone
              ? "/ 56 " + currentQuerySelected.account.phone
              : ""
          }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  computed: {
    claimantFullAddress() {
      let fullAddress = this.currentQuerySelected.account.address.streetAddress;

      if (this.currentQuerySelected.account.address.apartment != "") {
        if (
          this.currentQuerySelected.account.userType ==
          this.allConstants.security.userTypes.person
        ) {
          fullAddress =
            fullAddress +
            ", depto " +
            this.currentQuerySelected.account.address.apartment;
        } else {
          fullAddress =
            fullAddress +
            ", oficina " +
            this.currentQuerySelected.account.address.apartment;
        }
      }

      const allRegions = this.$Parameters.allParameters.find(
        (parameter) => parameter.type == "Regions"
      ).values;
      const region = allRegions.find(
        (region) =>
          region.code == this.currentQuerySelected.account.address.region
      );
      if (region != undefined) {
        const province = region.provinces.find(
          (province) =>
            province.code == this.currentQuerySelected.account.address.city
        );
        const commune = province.communes.find(
          (commune) =>
            commune.code == this.currentQuerySelected.account.address.commune
        );
        fullAddress =
          fullAddress +
          ", " +
          commune.name +
          ", " +
          province.name +
          ", " +
          region.name;
      } else {
        fullAddress =
          fullAddress + ", " + this.currentQuerySelected.account.address.region;
      }

      return fullAddress;
    },
  },
};
</script>

<style lang="scss" scoped></style>
