<template>
  <v-card
    height="100%"
    class="pa-1 ma-1"
    elevation="0"
    v-if="currentQuerySelected.query.state == 'PENDIENTE'"
  >
    <v-card-text>
      <v-form v-model="form.isValid" @submit.prevent="enter">
        <div v-if="askComments">
          <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
            Respuesta
          </div>
          <v-textarea
            v-model.trim="formFields.comments"
            :disabled="parentIsProcessing"
            maxlength="1024"
            rows="3"
            outlined
          ></v-textarea>
        </div>
        <div>
          <v-row>
            <v-col cols="12" md="3">
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Respuestas Tipo
              </div>
              <v-select
                outlined
                v-model.trim="formFields.typeResponseCode"
                :items="posibleValuesDrop()"
                placeholder="Seleccione"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn
            type="submit"
            color="green"
            class="mr-3 text-white"
            :disabled="this.ValidateControls() || page.loading"
            :loading="parentIsProcessing"
            ><v-icon>mdi-content-save-outline</v-icon> Grabar</v-btn
          >
        </div>
      </v-form>
      <v-dialog v-model="dialogConfirm" width="500" persistent>
        <v-card>
          <v-card-title class="grey lighten-2">
            ¿Está seguro de realizar esta acción?
          </v-card-title>

          <v-card-actions>
            <v-btn color="secundary" text @click="dialogConfirm = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="enterConfirm">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  props: {
    askComments: {
      type: Boolean,
      default: false,
    },
    parentIsProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formFields: {
        comments: "",
        commentsRules: [
          (value) => value.length <= 1024 || "Demasiado largo",
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexComments.test(value) || "No es válido",
        ],
        accion: "",
        accionRules: [(value) => !!value || "Requerido"],
        values: [],
      },
      typeResponseCode: [],
      dialogConfirm: false,
    };
  },
  computed: {},
  methods: {
    ValidateControls() {
      if (
        this.formFields.comments == "" &&
        this.formFields.typeResponseCode == null
      )
        return true;
      else {
        return false;
      }
    },
    enter() {
      this.dialogConfirm = true;
    },
    enterConfirm() {
      this.dialogConfirm = false;
      this.$emit(
        "executeAction",
        this.formFields.typeResponseCode,
        this.formFields.comments
      );
    },

    posibleValuesDrop() {
      let result = [];

      const typology = this.$Parameters.all.find(
        (parameter) => parameter.type == "TypeResponse"
      ).values;
      typology.forEach((t) => {
        result.push({ value: t.code, text: t.name });
      });

      return result;
    },
  },
  beforeMount() {
    this.initSignalLoading();

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped></style>
