<template>
  <div>
    <v-progress-linear
      indeterminate
      color="cyan"
      v-if="page.loading"
    ></v-progress-linear>
    <v-card class="pa-5" v-else>
      <v-row>
        <v-col cols="12" md="4" class="text-left"
          ><v-btn icon x-large @click="goBack()"
            ><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <div class="d-inline h3">
            Consulta Nº
            {{ currentQuerySelected.query.folio }}
          </div>
        </v-col>
        <v-col cols="12" md="4" class="text-right"
          ><v-chip>{{
            currentQuerySelected.query.state == "PENDIENTE"
              ? "Ingresada"
              : "Respondida"
          }}</v-chip
          ><br />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12"><DatesAndHistoryV2 /></v-col>
      </v-row>

      <v-row v-if="isSolved">
        <v-col cols="12" md="12"><LastHumanEventV2 /></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6"><CustomerInfoV2 /></v-col>
        <v-col cols="12" md="6"><BankInfoV2 /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6"><FactInfoV2 /></v-col>
        <v-col cols="12" md="6"
          ><DocumentsInfo :parentIsProcessing="form.processing"
        /></v-col>
      </v-row>

      <!-- <v-row v-if="currentQuerySelected.query.values.length > 0">
        <v-col cols="12" md="12"
          ><v-expansion-panels multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header
                ><span class="font-weight-bold d-block"
                  >Valores adicionales</span
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-chip
                  v-for="objValue in currentQuerySelected.query.values"
                  :key="objValue.name"
                  class="ma-2"
                  color="primary"
                >
                  {{ objValue.name }}: {{ getNameValue(objValue) }}
                </v-chip>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels></v-col
        >
      </v-row> -->

      <v-row>
        <v-col cols="12" md="12"
          ><Actions
            :askComments="true"
            :parentIsProcessing="form.processing"
            @executeAction="executeAction"
        /></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" class="text-left"
          ><v-btn icon x-large @click="goBack()"
            ><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn
          ></v-col
        >
      </v-row>
    </v-card>

    <v-dialog v-model="alert.show" max-width="400" persistent>
      <v-card>
        <div class="text-right">
          <v-btn icon @click="alert.show = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="pa-6 pt-1">
          <div class="headline text-center mb-2">Atención</div>
          <div class="text-center mb-2">{{ alert.message }}</div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewProcessing" max-width="400" persistent>
      <v-card>
        <v-card-title>Procesando</v-card-title>

        <v-card-text>
          <v-row v-if="viewDocumentProcess">
            <v-col cols="12" md="6">Archivos procesados:</v-col>
            <v-col cols="12" md="6"
              >{{ documentProcess }} de {{ countDocument }}</v-col
            >
          </v-row>
          <v-row v-if="documentFailed > 0">
            <v-col cols="12" md="6">Archivos con errores:</v-col>
            <v-col cols="12" md="6">{{ documentFailed }}</v-col>
          </v-row>
          <v-progress-linear
            indeterminate
            reverse
            color="primary darken-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorProcessing" max-width="400" persistent>
      <v-card>
        <div class="text-right">
          <v-btn icon @click="errorProcessing = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-card-title>Error en el procesamiento de archivos</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">Archivos con errores:</v-col>
            <v-col cols="12" md="6">{{ documentFailed }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentDashboardMixin from "@/mixins/currentDashboard";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

import DatesAndHistoryV2 from "@/components/WFSite/Queries/DatesAndHistoryV2";
import CustomerInfoV2 from "@/components/WFSite/Queries/CustomerInfoV2";
import BankInfoV2 from "@/components/WFSite/Queries/BankInfoV2";
import FactInfoV2 from "@/components/WFSite/Queries/FactInfoV2";
import DocumentsInfo from "@/components/WFSite/Queries/DocumentsInfo";
import LastHumanEventV2 from "@/components/WFSite/Queries/LastHumanEventV2";

import Actions from "@/components/WFSite/Queries/Actions";

import attachmentsService from "@/services/attachments";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentDashboardMixin,
    currentQuerySelectedMixin,
  ],
  components: {
    DatesAndHistoryV2,
    CustomerInfoV2,
    BankInfoV2,
    FactInfoV2,
    DocumentsInfo,
    LastHumanEventV2,
    Actions,
  },
  data() {
    return {
      dialogBack: false,
      panel: [0],
      viewProcessing: false,
      viewDocumentProcess: false,
      errorProcessing: false,
      documentProcess: 0,
      countDocument: 0,
      documentFailed: 0,
    };
  },
  computed: {
    lastActionCode() {
      return this.currentQuerySelected.query.lastActionCode;
    },
    isSolved() {
      return this.allConstants.dashboard.groups.ddcExecutive.queriesClosed.statuses.includes(
        this.currentQuerySelected.query.state
      );
    },
  },
  methods: {
    async executeAction(typeResponseCode, comments) {
      this.initSignalProcessing();
      this.viewProcessing = true;

      await this.changeWfStatusFromAction(typeResponseCode, comments);
      await this.loadDashboard();

      this.stopSignalProcessing();
      this.viewProcessing = false;
      this.goBack();
    },
    async uploadDocuments(documents) {
      for (let i = 0; i < documents.length; i++) {
        if (documents[i].blob != null) {
          documents[i].realName = documents[i].blob.name;
          documents[i].fileSize = documents[i].blob.size;
          documents[i].fileContentType = documents[i].blob.type;
          documents[i].internalId = this.$uuid.v4();

          documents[i].loading = true;

          const uploadResult = await this.uploadOneDocumentV2(
            documents[i].blob
          );

          documents[i].blobId = uploadResult.blobId;
          documents[i].loaded = uploadResult.loaded;

          documents[i].loading = false;
        }
      }
    },
    async uploadOneDocument(internalId, blob) {
      const addResult = await attachmentsService.add(blob);
      return {
        internalId: internalId,
        blobId: addResult.data.blobId,
      };
    },
    async uploadOneDocumentV2(blob) {
      const addResult = await attachmentsService.add(blob);
      if (addResult.status != 200) {
        this.documentFailed++;
        return {
          blobId: "00000000-0000-0000-0000-000000000000",
          loaded: false,
        };
      }

      this.documentProcess++;
      return {
        blobId: addResult.data.blobId,
        loaded: true,
      };
    },
    goBack() {
      console.log("goBack --> ", this.currentQuerySelected.fromRouteName);
      if (this.currentQuerySelected.fromRouteName == "dashboard") {
        //this.gotoView(this.currentQuerySelected.fromRouteName);
        this.dialogBack = true;
      } else this.gotoView(this.currentQuerySelected.fromRouteName);
    },
  },
  beforeMount() {
    this.initSignalLoading();
    this.stopSignalLoading();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
</style>
