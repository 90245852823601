<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Información del banco</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" class="text-center">
          <span class="font-weight-bold d-block">Banco</span>
          <span class="text-capitalize">{{
            getBankName(currentQuerySelected.query.finnancialInstitute)
          }}</span>
        </v-col>
        <v-col cols="12" md="5" class="text-center">
          <span class="font-weight-bold d-block">Producto</span>
          <span class="text-capitalize">{{
            getProductName(currentQuerySelected.query.productType)
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
};
</script>

<style lang="scss" scoped></style>
