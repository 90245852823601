<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Fecha ingreso</span>
          {{ currentQuerySelected.query.creationDate | formatDateShort }}
          {{ currentQuerySelected.query.creationDate | formatTimeShort }}
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Fecha actual</span>
          {{ new Date().toISOString() | formatDateShort }}
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Fecha tope respuesta</span>
          <span
            v-if="
              currentQuerySelected.query && currentQuerySelected.query.deadLine
            "
            >{{ currentQuerySelected.query.deadLine | formatDateShort }}</span
          >
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Plazo</span>
          <span v-if="currentQuerySelected.query">
            <span
              class="time-alert-red px-2 rounded-lg"
              v-if="currentQuerySelected.query.flag == 'Red'"
              ><v-icon dark small>mdi-clock-time-four-outline</v-icon></span
            >
            <span
              class="time-alert-green px-2 rounded-lg"
              v-if="currentQuerySelected.query.flag == 'Green'"
              ><v-icon dark small>mdi-clock-time-four-outline</v-icon></span
            >
            <span
              class="time-alert-yellow px-2 rounded-lg"
              v-if="currentQuerySelected.query.flag == 'Yellow'"
              ><v-icon small>mdi-clock-time-four-outline</v-icon></span
            >
            {{
              currentQuerySelected.query.howMuchLeft.value | formatDaysFromHours
            }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
