<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Documento</th>
            <th class="text-left">Fecha Creación</th>
            <th class="text-left">Archivo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in documents" :key="document.id">
            <td class="text-caption">{{ document.name }}</td>
            <td class="text-caption">
              {{ $d(new Date(document.creationDate), "short") }}
              {{ $d(new Date(document.creationDate), "justHour") }}
            </td>
            <td class="text-caption">
              <v-progress-linear
                buffer-value="0"
                stream
                color="cyan"
                v-if="parentIsProcessing"
              ></v-progress-linear>
              <router-link
                to="#"
                v-on:click.native="viewDocument(document)"
                v-else
              >
                <div v-if="document.fileName.length <= 30">
                  {{ document.fileName }}
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="one-line" v-bind="attrs" v-on="on"
                        >{{ document.fileName.substring(0, 30) }}...</span
                      >
                    </template>
                    <span>{{ document.fileName }}</span>
                  </v-tooltip>
                </div>
              </router-link>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog
        v-model="documentToView.show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="primary darken-3" dark>
            <v-btn
              icon
              dark
              @click="documentToView.show = false"
              :loading="documentToView.loading"
              ><v-icon>mdi-arrow-left-circle</v-icon></v-btn
            >
            <v-toolbar-title>{{ documentToView.data.name }}</v-toolbar-title>
          </v-toolbar>

          <div class="mt-3 mb-3 text-center h1">
            <v-icon x-large :color="iconDownload.color">
              {{ iconDownload.icon }}
            </v-icon>
            {{ documentToView.data.fileName }}
          </div>
          <div class="mt-3 mb-3 text-center">
            <v-btn
              color="primary"
              class="ma-2 white--text"
              :loading="documentToView.loading"
              :disabled="documentToView.exist"
              @click="downloadDocument"
            >
              Descargar
              <v-icon right dark>
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="ma-2 white--text"
              :loading="documentToView.loading"
              :disabled="documentToView.exist"
              @click="viewDocumentOnline"
              v-if="viewDocumentIframe"
            >
              Ver
              <v-icon right dark>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </div>
          <div class="mt-3 mb-3 text-center" v-if="documentToView.exist">
            <v-alert type="warning" elevation="1">
              El archivo ya no existe en nuestros registros
            </v-alert>
          </div>
          <div class="iframe-container">
            <v-progress-linear
              indeterminate
              color="cyan"
              class="ma-5"
              v-if="documentToView.loading"
            ></v-progress-linear>

            <iframe :src="file" ref="viewer" v-if="documentToView.viewOnline" />
          </div>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

import attachmentsService from "@/services/attachments";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  props: {
    parentIsProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documentToView: {
        show: false,
        loading: false,
        exist: false,
        data: {},
        viewOnline: false,
      },
      downloading: false,
      file: null,
    };
  },
  computed: {
    documentsUploadedByClaimant() {
      //Aquellos documentos subidos por el cliente reclamante final. Esos son los aprobables/rechazables unicamente por el usuario con rol "ejecutivo ddc"
      // let documentIds = [];

      // const creationEvent = this.currentQuerySelected.claim.events.filter(
      //   (event) => event.rol == "Claimant" || event.actionCode == ""
      // ); //evento de creación no tiene actionCode. entonces es blanco.

      // if (creationEvent == undefined || creationEvent.length == 0) return [];

      // creationEvent.forEach((event) => {
      //   event.documentIds.forEach((id) => {
      //     documentIds.push(id);
      //   });
      // });

      const filteredDocuments = this.currentQuerySelected.query.documents;

      /*
      const filteredDocuments = this.currentQuerySelected.claim.documents.filter(
        (document) => documentIds.includes(document.id)
      );
      */

      if (
        this.currentQuerySelected.query.state == "INGRESADO" ||
        this.currentQuerySelected.query.state == "PENDIENTE"
      ) {
        return filteredDocuments.map((document) => {
          document.approvable =
            this.currentUserInfo.roles[0] ==
            this.allConstants.security.userRolesCodes.ddcExecutive;
          return document;
        });
      } else {
        return filteredDocuments.map((document) => {
          document.approvable = false;
          return document;
        });
      }
    },

    documents() {
      const allDocuments = this.documentsUploadedByClaimant
        .concat
        //this.documentsNotUploadedByClaimant
        ();
      return allDocuments.filter((document) => !document.virtual);
    },
    iconDownload() {
      if (
        this.documentToView.data.contentType == "application/msword" ||
        this.documentToView.data.contentType ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
        return { color: "blue darken-2", icon: "mdi-microsoft-word" };

      if (
        this.documentToView.data.contentType == "application/vnd.ms-excel" ||
        this.documentToView.data.contentType ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return { color: "green darken-2", icon: "mdi-microsoft-excel" };

      if (
        this.documentToView.data.contentType ==
          "application/vnd.ms-powerpoint" ||
        this.documentToView.data.contentType ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      )
        return { color: "red darken-2", icon: "mdi-microsoft-powerpoint" };

      if (this.documentToView.data.contentType == "application/pdf")
        return { color: "red darken-2", icon: "mdi-file-pdf" };

      if (
        this.documentToView.data.contentType == "image/gif" ||
        this.documentToView.data.contentType == "image/jpeg" ||
        this.documentToView.data.contentType == "image/tiff" ||
        this.documentToView.data.contentType == "image/svg+xml" ||
        this.documentToView.data.contentType == "image/png"
      )
        return { color: "orange darken-2", icon: "mdi-file-image" };

      if (this.documentToView.data.contentType == "application/vnd.ms-outlook")
        return { color: "blue darken-2", icon: "mdi-microsoft-outlook" };

      return { color: "", icon: "" };
    },
    viewDocumentIframe() {
      if (
        this.documentToView.data.contentType == "application/pdf" ||
        this.documentToView.data.contentType == "image/gif" ||
        this.documentToView.data.contentType == "image/jpeg" ||
        this.documentToView.data.contentType == "image/tiff" ||
        this.documentToView.data.contentType == "image/svg+xml" ||
        this.documentToView.data.contentType == "image/png"
      )
        return true;

      return false;
    },
  },
  methods: {
    async viewDocument(documentData) {
      //Descargar y mostrar el documento
      this.documentToView.data = documentData;
      this.documentToView.show = true;
      this.documentToView.loading = true;
      this.documentToView.exist = false;
      this.documentToView.viewOnline = false;

      this.documentToView.loading = false;
    },
    downloadDocument() {
      this.documentToView.loading = true;
      this.documentToView.exist = false;

      attachmentsService
        .downlod(this.documentToView.data.blobId, "query")
        .then((downlodResult) => {
          const urlCreator = window.URL || window.webkitURL;
          const contentBlob = new Blob([downlodResult.data], {
            type: this.documentToView.data.contentType,
          });

          //this.$refs.a_download.src = urlCreator.createObjectURL(contentBlob);
          const fileURL = urlCreator.createObjectURL(contentBlob);
          //Open the URL on new Window
          window.open(fileURL);
          this.documentToView.loading = false;
        })
        .catch(() => {
          this.documentToView.exist = true;
          this.documentToView.loading = false;
        });
    },
    viewDocumentOnline() {
      this.documentToView.loading = true;
      this.documentToView.exist = false;
      this.documentToView.viewOnline = false;

      attachmentsService
        .downlod(this.documentToView.data.blobId, "query")
        .then((downlodResult) => {
          const urlCreator = window.URL || window.webkitURL;
          const contentBlob = new Blob([downlodResult.data], {
            type: this.documentToView.data.contentType,
          });

          this.documentToView.loading = false;
          this.documentToView.viewOnline = true;
          this.file = urlCreator.createObjectURL(contentBlob);
        })
        .catch(() => {
          this.documentToView.exist = true;
          this.documentToView.loading = false;
          this.documentToView.viewOnline = false;
        });
    },
  },
  async beforeMount() {
    //Identificar el resto de documentos. Esos no son aprobables/rechazables.
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  iframe {
    width: 100%;
    height: 100vh;
  }
}
</style>
