<template>
  <v-card color="#EEF5F7" class="pa-1 ma-1" elevation="2" height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Última acción del usuario</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" class="text-center">
          <span class="d-block font-weight-medium"
            >Ejecutivo Defensoria del Cliente
          </span>
          <span class="">{{
            currentQuerySelected.lastHumanEvent.userName
          }}</span>
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="d-block font-weight-medium">Fecha</span>
          <span class="">{{
            currentQuerySelected.query.lastUpdateDate | formatDateShort
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="d-block font-weight-medium">Respuesta</span>
          <span class="text-justify text-lowercase">{{
            currentQuerySelected.query.reply
          }}</span>
          <br />
          <span class="text-justify text-lowercase">{{
            typeReply(currentQuerySelected.query.typeResponseCode)
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  methods: {
    typeReply(value) {
      console.log("valor", value);
      let result = [];
      if (value != "") {
        const typology = this.$Parameters.all.find(
          (parameter) => parameter.type == "TypeResponse"
        ).values;
        typology.forEach((t) => {
          if (t.code == value) {
            result.push({ value: t.code, text: t.name });
          }
        });
        return result[0].text;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
